/*--------------------------------------------------------------------------
   c-select
---------------------------------------------------------------------------*/
.c-select {
  position: relative;
  display: inline-block;
	//width: 220px;
  width: 100%;
  height: 50px;
	background-color: #fff;
  border: 1px solid #333;
  border-radius: 10px;

	@include mq-sp {
		//height: 50px;
  }

  &::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    border: 5px solid $request-color;
    border-radius: 14px;
    visibility: hidden;
    animation: frame 1.5s ease infinite;
    pointer-events: none;
  }
  @keyframes frame {
    0%{ opacity:0; }
    50%{ opacity:1; }
    100%{ opacity:0; }
  }

  &.is-active {
    &::before {
      visibility: visible;
    }
  }

  select {
    width: 100%;
    height: 100%;
    padding: 0 25px 0 60px;
		font-size: 17px;
    font-weight: bold;
		cursor: pointer;
  }

  &__txt {
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
    width: 100%;
    padding-left: 60px;
    font-size: 0px;
    font-weight: bold;
    color: #aaa;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      width: 22px;
      height: 18px;
      background: url('../img/common/txt_select_01.svg') no-repeat 50% 50% / contain;
    }

    &::after {
      position: absolute;
      display: inline-block;
      content: '';
      pointer-events: none;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      width: 20px;
      height: 11px;
      background: url("../../assets/img/common/ico_arrow_select.svg") no-repeat 0 0/ cover;

      @include mq-sp {
        right: 14px;
        width: 14px;
        height: 8px;
      }
    }
  }

  select:disabled {
    color: #333;
    cursor: default;

    & + .c-select__txt {
      &::before,
      &::after {
          opacity: .5;
      }
    }
  }
}
