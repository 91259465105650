/*--------------------------------------------------------------------------
   l-flex-grid
---------------------------------------------------------------------------*/
.l-flex-grid {
	display: flex;
	box-sizing: border-box;
	flex-wrap: wrap;

	&__item {
		box-sizing: inherit;
	}
}

.l-flex-grid__item {
	&--full {
		width: 100%;
	}

	&--1of2 {
		width: 50%;
	}

	&--1of3 {
		width: 33.33333333%;
	}

	&--1of4 {
		width: 25%;
	}

	&--1of5 {
		width: 20%;
	}

	&--1of6 {
		width: 16.66666666%;
	}
}
