/*--------------------------------------------------------------------------
   init
---------------------------------------------------------------------------*/
// init
.is-animate {
  &.is-fadein {
    opacity: 0;
  }

  &.is-fadeup {
    opacity: 0;
    transform: translateY(30px);
  }
  &.is-fadedown {
    opacity: 0;
    transform: translateY(-30px);
  }
  &.is-fadeleft {
    opacity: 0;
    transform: translateX(30px);
  }
  &.is-faderight {
    opacity: 0;
    transform: translateX(-30px);
  }
  &.is-fadezoom {
    opacity: 0;
    transform: scale(.4);
  }
}


/*--------------------------------------------------------------------------
   animated
---------------------------------------------------------------------------*/
.is-animated {

  &.is-fadein {
    opacity: 1;
    transition: opacity .6s ease-in-out;
    transition-delay: 0s;
  }

  &.is-fadeup {
    opacity: 1;
    transform: translateY(0);
    transition: opacity .6s ease-in-out, transform 1s $ease-out-circ;
    transition-delay: 0s;
  }

  &.is-fadedown {
    opacity: 1;
    transform: translateY(0);
    transition: opacity .6s ease-in-out, transform 1s $ease-out-circ;
    transition-delay: 0s;
  }

  &.is-fadeleft {
    opacity: 1;
    transform: translateX(0);
    transition: opacity .6s ease-in-out, transform 1s $ease-out-circ;
    transition-delay: 0s;
  }

  &.is-faderight {
    opacity: 1;
    transform: translateX(0);
    transition: opacity .6s ease-in-out, transform 1s $ease-out-circ;
    transition-delay: 0s;
  }

  &.is-fadezoom {
    opacity: 1;
    transform: scale(1);
    transition: opacity .6s ease-in-out, transform 1s $ease-out-circ;
    transition-delay: 0s;
  }
}
