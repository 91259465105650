/*--------------------------------------------------------------------------
   .g-footer
---------------------------------------------------------------------------*/
.g-footer {
	@include mq-sp {
    padding-bottom: 64px;
	}

	&__pagetop {
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    position: fixed;
    right: 20px;
    bottom: 70px;
    z-index: 9;
    transition: .3s;

    @include mq-sp {
      right: 15px;
      bottom: 85px;
		}

    a {
      display: block;
      width: 46px;
      height: 46px;
      transition: scale .2s;

      @include mq-sp {
        width: 40px;
        height: 40px;
      }

      img {
        width: 100%;
        height: auto;
      }

      &:hover {
        scale: 1.2;
      }
    }

    &.is-show {
      opacity: 1;
      pointer-events: all;
      overflow: visible;
    }
	}

  &__bnrs {
    padding: 20px 0;
    background: #f5f1e5;

		@include mq-sp {
      padding-bottom: 10px;
		}
  }

  &__bnrlist {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &-item {
      padding: 0px 10px;
      background: #fff;
      border-radius: 10px;
      font-size: 21px;

      @include mq-sp {
        margin-bottom: 10px;
      }

      &:not(:first-of-type) {
        margin-left: 20px;

        @include mq-sp {
          margin-left: 10px;
        }
      }
    }
  }

	&__logo-area {
    padding: 30px 0;
    text-align: center;

		@include mq-sp {
      padding: 20px 0;
		}

		.logo {
      width: 238px;
      margin: 0 auto;

      img {
        width: 100%;
      }
		}
	}

	&__copy {
    padding: 10px 0;
    background: #4d4d4d;
		font-size: 12px;
    font-weight: 500;
		color: #fff;
    letter-spacing: .1em;
    text-align: center;

		@include mq-sp {
		}
	}
}
