/*--------------------------------------------------------------------------
   l-grid
---------------------------------------------------------------------------*/
.l-inline-grid {
	box-sizing: border-box;
	white-space: nowrap;

	&__item {
		display: inline-block;
		box-sizing: inherit;
	}
}

.l-inline-grid__item {
	&--1of2 {
		width: 50%;
	}

	&--1of3 {
		width: 33.33333333%;
	}

	&--1of4 {
		width: 25%;
	}

	&--1of5 {
		width: 20%;
	}

	&--1of6 {
		width: 16.66666666%;
	}
}
