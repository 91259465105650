/*--------------------------------------------------------------------------
   l-page
---------------------------------------------------------------------------*/
.l-page-wrapper {
	overflow: hidden;
}

.is-loading .l-page-wrapper {
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.l-page {
	&__main {
    //padding-top: 137px;
	}
}


/* media query -> sp
=================================================================*/
@media #{$bp-sp} {
	.l-page-wrapper {
		overflow: hidden;
		width: 100%;
		height: 100%;
	}

	.l-page {
		overflow-x: hidden;
		overflow-y: auto;
		width: 100%;
		height: 100%;

		-webkit-overflow-scrolling: touch;

    &__main {
      //padding-top: 68px;
    }

	}

	.is-loading .l-page {
		overflow-y: hidden;
	}
}
