/*--------------------------------------------------------------------------
   cf
---------------------------------------------------------------------------*/
.cf {
  *zoom: 1;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
