/*--------------------------------------------------------------------------
   SHOW / HIDE
---------------------------------------------------------------------------*/

/*
 show / hide
 -- レスポンシブ用 表示/非表示
*/

.mi-pc{
  display: block;
}
img.mi-pc{
  display: inline;
}
.mi-sp{
  display: none;
}

/* media query -> sp
========================================*/
@media #{$bp-sp} {
  .mi-pc,
  img.mi-pc{
    display: none;
  }
  .mi-sp{
    display: block;
  }
  img.mi-sp{
    display: inline;
  }
}


/*--------------------------------------------------------------------------
   TEXT
---------------------------------------------------------------------------*/

/*
 note
 -- 改行時、一文字目に余白を持たせる
*/

.mi-note,
.mi-list-note li{
  padding-left:1em;
  text-indent:-1em;
}


/*--------------------------------------------------------------------------
   IMAGE
---------------------------------------------------------------------------*/

/*
 max
 -- 横幅に合わせて画像を最大化
*/

.mi-img-max{
  width: 100%;
  height: auto;
}
