/*--------------------------------------------------------------------------
   m-slide
---------------------------------------------------------------------------*/
.m-slide {
  max-width: calc(100vw - 80px);
  margin: 0 auto;

  .swiper {
    overflow: visible;
  }

  .swiper-button-prev,
  .swiper-button-next {
    margin-top: -34px;
    width: 74px;
    height: 74px;
    background: #000;
    border-radius: 50%;
    transition: .3s;

    @include mq-sp {
      margin-top: -19px;
      width: 37px;
      height: 37px;
    }

    &::after {
      font-size: 30px;
      color: #fff;
      @include mq-sp {
        font-size: 20px;
      }
    }

    &:hover {
      opacity: .8;
    }
  }

  .swiper-button-prev {
    margin-left: -40px;
    @include mq-sp {
      margin-left: -20px;
    }
  }
  .swiper-button-next {
    margin-right: -40px;
    @include mq-sp {
      margin-right: -20px;
    }
  }
}
