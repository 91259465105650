// ==========================================================================
//
//   変数
//
// ==========================================================================

// font-family
$font-family: "YakuHanJP", "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", 
  "Noto Sans JP", "Hiragino Sans",
  "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3",
  "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", Osaka, Verdana, sans-serif;
$font-family-serif: 'Noto Serif JP', serif, sans-serif;

$font-family-en: 'Montserrat', sans-serif;
$font-family-anton: 'Anton', sans-serif;
$font-family-roboto: 'Roboto', sans-serif;

// font-size
$font-size-pc: 14px;
$font-size-sp: 12px;

// font-weight
$font-weight-win: 600;
$font-weight-mac: 600;

$font-weight-bold: 600;
$font-weight-bold-en: 600;

// line-height
$line-height: 1.8;
$line-height-sp: 1.5;

// width
$min-width-sp: 320px;
$min-width-pc: 1020px;
$width-pc: 1440px;

// breakpoint
$bp-sp-min: "only screen and (max-width: 374px)";
$bp-sp: "only screen and (max-width: 767px)";
$bp-tb: "only screen and (min-width: 768px) and (max-width: 1280px)";
$bp-pc: "only screen and (min-width: 1281px) and (max-width: 1439px)";

// color
$key-color: #fff69c;
$contact-color: #b1e3e9;
$request-color: #ff6964;
$font-color-basic: #111;
$font-color-sub: #707F89;
$em-color: #ff6964;

// selection
$selection-background-color: #b3d4fc;
$selection-color: #fff;
$selection-text-shadow: none;

// link
$link-color: inherit;
$link-tap-highlight-color: rgba(0, 0, 0, 0);
$link-text-decoration: none;

// easing
$ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
$ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
$ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);

$ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);

$ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);

$ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);

$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);

$ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$ease-in-out-expo: cubic-bezier(1, 0, 0, 1);

$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
$ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);

$ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);
