/*--------------------------------------------------------------------------
   .g-header
---------------------------------------------------------------------------*/
.g-header {
	position: absolute;
	z-index: 999;
	width: 100%;
  height: 76px;
	// min-width: $min-width-pc;
  background: #fff;

  @include mq-sp {
    height: 55px;
  }

	&.is-fixed {
		position: fixed;
	}

	&__logo {
		position: absolute;
		top: 16px;
		left: 11.33vw;
		z-index: 99;
		transition: top .4s;

		@media screen and (min-width:768px) and (max-width:1200px) {
      left: 50px;
		}
		@media screen and (min-width:768px) and (max-width:900px) {
      top: 20px;
      left: 0px;
		}
    @include mq-sp {
      top: 11px;
      left: 12px;
    }

		a {
			display: inline-block;
		}

		img {
			width: 316px;
			height: auto;
			transition: .3s;

			@media screen and (min-width:768px) and (max-width:900px) {
				width: 246px;
			}
      @include mq-sp {
        width: 221px;
      }
    }
	}

	&__inquiry {
		display: flex;
		position: absolute;
		top: 0;
		right: 110px;

    @include mq-sp {
      display: none;
    }

    .ico {
      margin-right: 8px;
      line-height: 0;
    }
	}

	&__contact {
    width: 198px;
    height: 76px;
    font-size: 20px;
    font-weight: bold;

		a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: $contact-color;

      &:hover {
        opacity: .8;
      }
		}
	}

	&__request {
    width: 198px;
    height: 76px;
    font-size: 20px;
    font-weight: bold;

		a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: $request-color;
      color: #fff;

      &:hover {
        opacity: .8;
      }
		}
	}

	&__menubtn {
		/*display: flex;
		align-items: center;
		justify-content: center;*/
		position: absolute;
		top: 0;
		right: 0;
		width: 110px;
		height: 76px;
		box-sizing: border-box;
		cursor: pointer;
		z-index: 99;

    @include mq-sp {
      width: 55px;
      height: 55px;
    }

    &::after {
      content: 'メニュー';
      display: block;
      position: absolute;
      bottom: 3px;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      font-size: 16px;
      font-weight: bold;

      @include mq-sp {
        content: none;
        font-size: 10px;
      }
    }

		.mode-pc &:hover .lines,
		.mode-sp &:active .lines {
			animation: h_menu_bounce 2s ease-in-out;

			@keyframes h_menu_bounce {
				5%  { transform: scale(1.5, 1); }
				10% { transform: scale(.7, 1); }
				15% { transform: scale(1, 1); }
			}
		}

		.lines {
			position: absolute;
			top: 20px;
			left: 35px;
			// overflow: hidden;
			margin: 0;
			padding: 0;
			width: 38px;
			height: 22px;
			font-size: 0;
			text-indent: -9999px;
			appearance: none;
			box-shadow: none;
			border-radius: 0;
			border: none;

      @include mq-sp {
        top: 19px;
        left: 13px;
        width: 28px;
        height: 16px;
      }

			&:focus {
				outline: none;
			}

			span {
				display: inline-block;
				position: absolute;
				left: 0;
				width: 100%;
				height: 2px;
				transition: all .4s;
				box-sizing: border-box;
				background-color: $font-color-basic;

				&:nth-of-type(1) {
					top: 0px;
					animation: menu-bar01 .75s forwards;
				}
				&:nth-of-type(2) {
					top: 10px;
					transition: all .25s linear;
					opacity: 1;

          @include mq-sp {
            top: 7px;
          }
        }
				&:nth-of-type(3) {
					bottom: 0px;
					animation: menu-bar02 .75s forwards;
				}
			}
		}
	}

	&__wrap {
		position: relative;
		z-index: 9;
		position: absolute;
		top: 0px;
		right: 0;
		// transform: translateY(-100%);
    max-width: 800px;
		width: 100%;
		height: calc(100vh - 0px);
		background-color: #fff;
		pointer-events: none;
		visibility: hidden;
		opacity: 0;
    transition: all .2s linear;

    @include mq-sp {
      height: calc(var(--vh) * 100);
    }
	}

  &__wrap-inner {
    padding: 90px;

    @include mq-sp {
      height: 100%;
      padding: 60px 0 0px;
      //overflow: auto;
    }
  }

	&__gnav {
		width: 100%;
		height: 100%;
		min-height: 0;
		overflow: auto;

		.gnav {
      width: 50%;
      @include mq-sp {
        width: auto;
        padding: 0 20px;
      }

			&__item {
        margin-bottom: 30px;
        @include mq-sp {
          margin-bottom: 0;
        }
      }

			&__item > a {
				position: relative;
        font-size: 17px;
				font-weight: 600;

        @include mq-sp {
          display: block;
          padding: 8px 5px 8px 20px;
          border-bottom: 1px solid #ccc;
          font-size: 18px;
        }

				.mode-pc &:hover,
				.mode-sp &:active {
					color: $request-color;
				}
			}
		}
	}

  &__gnav-wrap {
    display: flex;

    @include mq-sp {
      display: block;
    }
  }

  .sp-wrap {
    display: none;

    @include mq-sp {
      display: block;
      margin: 30px 0px 0;
      padding: 20px 50px 30px;
      background: $key-color;
    }

    .sp-request {
      font-weight: bold;
      text-align: center;

      &__txt01 {
        font-size: 19px;

        span {
          font-size: 35px;
          line-height: 1;
        }
      }

      &__txt02 {
        font-size: 29px;
      }
    }

    .m-request-select {
      margin: 20px 0 0;
    }

    .c-btn-request,
    .c-btn-contact {
      width: 100%;
      height: 50px;
      margin-top: 20px;

      a,
      button {
        box-shadow: 2px 2px 4px rgba(#000, .2);
      }
    }
  }

  .inquiry {
    display: flex;
    margin-top: 40px;

    @include mq-sp {
      display: none;
    }

    .c-btn-contact {
      margin-right: 26px;
    }
  }

  &__fuji {
    margin-top: 40px;

    @include mq-sp {
      margin-top: 20px;
      padding-bottom: 20px;
      text-align: center;
    }

    img {
      width: 167px;

      @include mq-sp {
        width: 213px;
      }
    }

    a {
      position: relative;
      @include mq-sp {
        padding-right: 20px;
      }

      &::after {
        content: '';
        position: absolute;
        top: 2px;
        right: -18px;
        width: 13px;
        height: 13px;
        background: url('../img/common/ico_blank.svg') no-repeat 50% 50% / cover;

        @include mq-sp {
          top: 4px;
          right: -6px;
          width: 17px;
          height: 17px;
        }
      }

      &:hover {
        opacity: .7;
      }
    }
  }
}

/*menu open*/
.is-menuopen {
	overflow: hidden;

	.g-header {
		.g-header__logo {
		}

		&__menubtn {
			/*border: 1px solid #fff;
			background-color: transparent;*/

      &::after {
        content: '閉じる';
      }
		}

		.lines {
			span {
				&:nth-of-type(1) {
					transform: translateY(10px) rotate(-45deg);
          @include mq-sp {
            transform: translateY(6px) rotate(-45deg);
          }
        }
				&:nth-of-type(2) {
					opacity: 0;
				}
				&:nth-of-type(3) {
					transform: translateY(-10px) rotate(45deg);
          @include mq-sp {
            transform: translateY(-8px) rotate(45deg);
          }
				}
			}
		}

		&__wrap {
			pointer-events: all;
			visibility: visible;
			opacity: 1;
      transition: all .4s linear;
		}
	}
}

.is-scroll.g-header {
	//
}
