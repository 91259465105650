/*--------------------------------------------------------------------------
   c-btn
---------------------------------------------------------------------------*/
.c-btn-contact {
  width: 198px;
  height: 64px;
  font-size: 17px;
  font-weight: bold;

  a,
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: $contact-color;
    border-radius: 9px;

    &:hover {
      opacity: .8;
    }
  }

  .ico {
    margin-right: 8px;
    line-height: 0;
  }
}

.c-btn-request {
  width: 198px;
  height: 64px;
  font-size: 17px;
  font-weight: bold;

  a,
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: $request-color;
    color: #fff;
    border-radius: 9px;
    transition: opacity .4s ease-in-out;

    &:hover {
      opacity: .8;
    }

    &:disabled {
      background: #aaa;
      opacity: .7;

      &:hover {
        opacity: .7;
      }
    }
  }

  .ico {
    margin-right: 8px;
    line-height: 0;
  }
}

.c-btn-tel {
  width: 198px;
  height: 64px;
  font-size: 17px;
  font-weight: bold;

  a,
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #fff271;
    border-radius: 9px;

    &:hover {
      opacity: .8;
    }
  }

  .ico {
    margin-right: 8px;
    line-height: 0;
  }
}
