/**
 *
 *  LOADER
 *
 */

/*--------------------------------------------------------------------------
   loader
---------------------------------------------------------------------------*/
#Loader {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 1);
  z-index: 9999999;
}

#Loader .progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 0px;
  height: 5px;
  background: $key-color;
}

#Loader .base {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
  height: 189px;
  //background-image: url(../img/common/logo_loader.svg);
  background-size: contain;
  background-position: 50% 50%;
  opacity: 0;
  z-index: 9;

  @include mq-sp {
    width: 224px;
    height: 132px;
  }
}

.page-index #Loader {
  display: block;
}


/* trans
-------------------------------------------*/
#Loader {

}

/* anima
-------------------------------------------*/
// loading none
.load-complete .g-main {
  opacity: 1;
  transition: all .6s ease-in-out;
  transition-delay: .4s;
}

// loading
.is-loading .page-index #Loader .base {
  opacity: 1;
  transition: opacity .6s ease-in-out;
}

.is-loaded .page-index #Loader {
  .base {
    transition: all .6s ease-in-out;
    opacity: 0;
  }
}

.load-complete .page-index #Loader {
  opacity: 0;
  transition: all .6s ease-in-out;
  transition-delay: .4s;
}
