/*--------------------------------------------------------------------------
   m-sp-footbtn
---------------------------------------------------------------------------*/
.m-sp-footbtn {
  display: none;

  @include mq-sp {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(100px);
    z-index: 9;
    transition: .3s;
  }

  &.is-show {
    transform: translateY(0);
  }

  &__list {
    display: flex;
  }

  &__btn {
    width: 50%;

    &.c-btn-contact {
      a {
        border-radius: 0;
      }
    }

    &.c-btn-request {
      a {
        border-radius: 0;
      }
    }
  }
}
