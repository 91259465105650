@charset "UTF-8";
/*==========================================================================

   mixin

===========================================================================*/
/* webfont */
@import url("//fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("//fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;500;600;700&display=swap");
@import url("//fonts.googleapis.com/css2?family=Anton&family=Montserrat:wght@400;500;600&family=Roboto:wght@500;700&display=swap");
@import url("//cdn.jsdelivr.net/npm/yakuhanjp@3.0.0/dist/css/yakuhanjp.min.css");
@import url("//cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css");
/* local */
/*--------------------------------------------------------------------------
   html
---------------------------------------------------------------------------*/
html {
  position: relative;
  min-height: 100%;
}
@media only screen and (max-width: 767px) {
  html {
    width: 100%;
    height: 100%;
  }
}
html.is-menuopen {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/*--------------------------------------------------------------------------
   head
---------------------------------------------------------------------------*/
head {
  font-family: "pc";
}
@media only screen and (max-width: 767px) {
  head {
    font-family: "sp";
  }
}

/*--------------------------------------------------------------------------
   body
---------------------------------------------------------------------------*/
body {
  color: #111;
  font-family: "YakuHanJP", "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", Osaka, Verdana, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media only screen and (max-width: 767px) {
  body {
    width: 100%;
    min-width: 320px;
    height: auto;
    font-size: 12px;
    line-height: 1.5;
  }
}

.os-mac body {
  font-weight: 600;
}

/*--------------------------------------------------------------------------
   a
---------------------------------------------------------------------------*/
a {
  outline: none;
  color: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: 0.3s;
}
a:link, a:visited {
  text-decoration: none;
}

/*--------------------------------------------------------------------------
   reset
---------------------------------------------------------------------------*/
* {
  word-wrap: break-word;
  outline: none;
}

img {
  vertical-align: top;
  height: auto;
}

ul, ol {
  list-style: none;
}

/* Placeholder
-----------------------------------------------*/
::-webkit-input-placeholder {
  color: #B7BEC4;
}

::-moz-placeholder {
  color: #B7BEC4;
}

:-ms-input-placeholder {
  color: #B7BEC4;
}

:-moz-placeholder {
  color: #B7BEC4;
}

/*--------------------------------------------------------------------------
   selection
---------------------------------------------------------------------------*/
::-moz-selection {
  background-color: #b3d4fc;
  color: #fff;
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc;
  color: #fff;
  text-shadow: none;
}

/* ----------------------------------------------------------------------------------------------------

Super Form Reset

A couple of things to watch out for:

- IE8: If a text input doesn't have padding on all sides or none the text won't be centered.
- The default border sizes on text inputs in all UAs seem to be slightly different. You're better off using custom borders.
- You NEED to set the font-size and family on all form elements
- Search inputs need to have their appearance reset and the box-sizing set to content-box to match other UAs
- You can style the upload button in webkit using ::-webkit-file-upload-button
- ::-webkit-file-upload-button selectors can't be used in the same selector as normal ones. FF and IE freak out.
- IE: You don't need to fake inline-block with labels and form controls in IE. They function as inline-block.
- By turning off ::-webkit-search-decoration, it removes the extra whitespace on the left on search inputs

----------------------------------------------------------------------------------------------------*/
input,
label,
select,
button,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
}

/* Remove the stupid outer glow in Webkit */
input:focus,
select:focus,
textarea:focus {
  outline: 0;
}

/* Box Sizing Reset
-----------------------------------------------*/
/* All of our custom controls should be what we expect them to be */
input,
textarea {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  white-space: pre-wrap;
  word-wrap: break-word;
}

/* These elements are usually rendered a certain way by the browser */
button,
input[type=reset],
input[type=button],
input[type=submit],
input[type=checkbox],
input[type=radio],
select {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* Text Inputs
-----------------------------------------------*/
/* Button Controls
-----------------------------------------------*/
input[type=checkbox],
input[type=radio] {
  width: 13px;
  height: 13px;
}

/* File Uploads
-----------------------------------------------*/
/* Search Input
-----------------------------------------------*/
/* Make webkit render the search input like a normal text field */
input[type=search] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
}

/* Turn off the recent search for webkit. It adds about 15px padding on the left */
::-webkit-search-decoration {
  display: none;
}

/* Buttons
-----------------------------------------------*/
button,
input[type=reset],
input[type=button],
input[type=submit] {
  /* Fix IE7 display bug */
  overflow: visible;
  width: auto;
}

/* IE8 and FF freak out if this rule is within another selector */
::-webkit-file-upload-button {
  padding: 0;
  border: 0;
  background: none;
}

/* Textarea
-----------------------------------------------*/
textarea {
  /* Move the label to the top */
  vertical-align: top;
  /* Turn off scroll bars in IE unless needed */
  overflow: auto;
}

/* Selects
-----------------------------------------------*/
select[multiple] {
  /* Move the label to the top */
  vertical-align: top;
}

/*--------------------------------------------------------------------------
   l-page
---------------------------------------------------------------------------*/
.l-page-wrapper {
  overflow: hidden;
}

.is-loading .l-page-wrapper {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* media query -> sp
=================================================================*/
@media only screen and (max-width: 767px) {
  .l-page-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  .l-page {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
  }
  .is-loading .l-page {
    overflow-y: hidden;
  }
}
/*--------------------------------------------------------------------------
   l-wrap
---------------------------------------------------------------------------*/
.l-wrap {
  position: relative;
  max-width: 1020px;
  margin: 0 auto;
  padding: 0 10px;
}

.l-wrap-s {
  position: relative;
  max-width: 954px;
  margin: 0 auto;
  padding: 0 10px;
}

/* media query -> sp
=================================================================*/
@media only screen and (max-width: 767px) {
  .l-wrap {
    max-width: none;
    padding: 0 20px;
  }
  .l-wrap-s {
    max-width: none;
    padding: 0 20px;
  }
}
/*--------------------------------------------------------------------------
   l-grid
---------------------------------------------------------------------------*/
.l-grid {
  box-sizing: border-box;
  *zoom: 1;
}
.l-grid:after {
  content: "";
  display: table;
  clear: both;
}
.l-grid__item {
  display: block;
  box-sizing: inherit;
  float: left;
}

.l-grid__item--1of2 {
  width: 50%;
}
.l-grid__item--1of3 {
  width: 33.33333333%;
}
.l-grid__item--1of4 {
  width: 25%;
}
.l-grid__item--1of5 {
  width: 20%;
}
.l-grid__item--1of6 {
  width: 16.66666666%;
}

/*--------------------------------------------------------------------------
   l-grid
---------------------------------------------------------------------------*/
.l-inline-grid {
  box-sizing: border-box;
  white-space: nowrap;
}
.l-inline-grid__item {
  display: inline-block;
  box-sizing: inherit;
}

.l-inline-grid__item--1of2 {
  width: 50%;
}
.l-inline-grid__item--1of3 {
  width: 33.33333333%;
}
.l-inline-grid__item--1of4 {
  width: 25%;
}
.l-inline-grid__item--1of5 {
  width: 20%;
}
.l-inline-grid__item--1of6 {
  width: 16.66666666%;
}

/*--------------------------------------------------------------------------
   l-flex-grid
---------------------------------------------------------------------------*/
.l-flex-grid {
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
}
.l-flex-grid__item {
  box-sizing: inherit;
}

.l-flex-grid__item--full {
  width: 100%;
}
.l-flex-grid__item--1of2 {
  width: 50%;
}
.l-flex-grid__item--1of3 {
  width: 33.33333333%;
}
.l-flex-grid__item--1of4 {
  width: 25%;
}
.l-flex-grid__item--1of5 {
  width: 20%;
}
.l-flex-grid__item--1of6 {
  width: 16.66666666%;
}

/*--------------------------------------------------------------------------
   c-btn
---------------------------------------------------------------------------*/
.c-btn-contact {
  width: 198px;
  height: 64px;
  font-size: 17px;
  font-weight: bold;
}
.c-btn-contact a,
.c-btn-contact button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #b1e3e9;
  border-radius: 9px;
}
.c-btn-contact a:hover,
.c-btn-contact button:hover {
  opacity: 0.8;
}
.c-btn-contact .ico {
  margin-right: 8px;
  line-height: 0;
}

.c-btn-request {
  width: 198px;
  height: 64px;
  font-size: 17px;
  font-weight: bold;
}
.c-btn-request a,
.c-btn-request button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #ff6964;
  color: #fff;
  border-radius: 9px;
  transition: opacity 0.4s ease-in-out;
}
.c-btn-request a:hover,
.c-btn-request button:hover {
  opacity: 0.8;
}
.c-btn-request a:disabled,
.c-btn-request button:disabled {
  background: #aaa;
  opacity: 0.7;
}
.c-btn-request a:disabled:hover,
.c-btn-request button:disabled:hover {
  opacity: 0.7;
}
.c-btn-request .ico {
  margin-right: 8px;
  line-height: 0;
}

.c-btn-tel {
  width: 198px;
  height: 64px;
  font-size: 17px;
  font-weight: bold;
}
.c-btn-tel a,
.c-btn-tel button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #fff271;
  border-radius: 9px;
}
.c-btn-tel a:hover,
.c-btn-tel button:hover {
  opacity: 0.8;
}
.c-btn-tel .ico {
  margin-right: 8px;
  line-height: 0;
}

/*--------------------------------------------------------------------------
   form
---------------------------------------------------------------------------*/
input,
button,
select,
textarea {
  border-radius: 0;
  -ms-appearance: none;
  appearance: none;
}

form input,
form textarea {
  border: 0;
}

input[type=checkbox],
input[type=radio] {
  position: absolute;
  opacity: 0;
}

/* 入力 */
.c-input {
  width: 100%;
  height: 60px;
  padding: 0 10px 0 20px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  font-size: 16px;
}
@media only screen and (max-width: 767px) {
  .c-input {
    height: 50px;
    padding: 5px 15px;
    font-size: 14px;
  }
}

.c-textarea {
  width: 100%;
  max-height: 240px;
  padding: 16px 10px 15px 20px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  font-size: 16px;
  overflow: auto;
}
@media only screen and (max-width: 767px) {
  .c-textarea {
    max-height: 200px;
    padding: 10px 12px;
    font-size: 14px;
  }
}

.c-input.ipt-err,
.c-textarea.ipt-err {
  background-color: #ffe4e4;
}

/* radio */
.c-radio + label {
  position: relative;
  display: inline-block;
  padding-left: 30px;
  border-radius: 50%;
  box-sizing: border-box;
  vertical-align: middle;
  cursor: pointer;
}
.c-radio + label::before, .c-radio + label::after {
  position: absolute;
  top: 50%;
  border-radius: 50%;
  content: "";
}
.c-radio + label::before {
  left: 0;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  border: 1px solid #b7b7b7;
  background: #fff;
}
.c-radio + label::after {
  left: 4px;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  background-color: #fff69c;
  opacity: 0;
}
.c-radio:checked + label::after {
  opacity: 1;
  transform: scale(1);
}

/* checkbox */
.c-checkbox {
  visibility: hidden;
  position: absolute;
}
.c-checkbox + label {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  padding-left: 30px;
  vertical-align: middle;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .c-checkbox + label {
    padding-left: 28px;
  }
}
.c-checkbox + label::before {
  display: block;
  position: absolute;
  top: 5px;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #b7b7b7;
  background: #fff;
  content: "";
}
@media only screen and (max-width: 767px) {
  .c-checkbox + label::before {
    top: 0;
    width: 20px;
    height: 20px;
  }
}
.c-checkbox + label::after {
  display: block;
  position: absolute;
  top: 6px;
  left: 6px;
  width: 8px;
  height: 16px;
  border-right: 3px solid #fff69c;
  border-bottom: 3px solid #fff69c;
  content: "";
  transform: rotate(45deg);
  opacity: 0;
}
@media only screen and (max-width: 767px) {
  .c-checkbox + label::after {
    top: 2px;
    left: 6px;
    width: 8px;
    height: 14px;
  }
}

.c-checkbox:checked + label::after {
  opacity: 1;
}

.mwform-checkbox-field .c-checkbox + span {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  padding-left: 30px;
  vertical-align: middle;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .mwform-checkbox-field .c-checkbox + span {
    padding-left: 28px;
  }
}
.mwform-checkbox-field .c-checkbox + span::before {
  display: block;
  position: absolute;
  top: 5px;
  left: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #b7b7b7;
  background: #fff;
  content: "";
}
@media only screen and (max-width: 767px) {
  .mwform-checkbox-field .c-checkbox + span::before {
    top: 0;
    width: 20px;
    height: 20px;
  }
}
.mwform-checkbox-field .c-checkbox + span::after {
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 8px;
  height: 14px;
  border-right: 3px solid #fff69c;
  border-bottom: 3px solid #fff69c;
  content: "";
  transform: rotate(45deg);
  opacity: 0;
}
@media only screen and (max-width: 767px) {
  .mwform-checkbox-field .c-checkbox + span::after {
    top: 2px;
    left: 6px;
    width: 8px;
    height: 14px;
  }
}
.mwform-checkbox-field .c-checkbox:checked + span::after {
  opacity: 1;
}

/* select
.c-select {
  position: relative;
  display: inline-block;
	width: 220px;
  height: 50px;
	background-color: #fff;
  border: 1px solid #333;
  border-radius: 10px;

	@include mq-sp {
		height: 50px;
  }

  &::before {
    position: absolute;
    display: inline-block;
    //content: '';
    pointer-events: none;
    top: 50%;
    right: 20px;
		transform: translateY(-50%);
    width: 20px;
    height: 11px;
		background: url("../../assets/img/common/ico_arrow_select.svg") no-repeat 0 0/ cover;

		@include mq-sp {
	    right: 14px;
      width: 14px;
      height: 8px;
    }
  }

  select {
    width: 100%;
    height: 100%;
    padding: 0 25px 0 60px;
		font-size: 17px;
    font-weight: bold;
		cursor: pointer;

		@include mq-sp {
    }
  }
} */
/*--------------------------------------------------------------------------
   c-select
---------------------------------------------------------------------------*/
.c-select {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 50px;
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 10px;
}
.c-select::before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  border: 5px solid #ff6964;
  border-radius: 14px;
  visibility: hidden;
  animation: frame 1.5s ease infinite;
  pointer-events: none;
}
@keyframes frame {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.c-select.is-active::before {
  visibility: visible;
}
.c-select select {
  width: 100%;
  height: 100%;
  padding: 0 25px 0 60px;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
}
.c-select__txt {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  width: 100%;
  padding-left: 60px;
  font-size: 0px;
  font-weight: bold;
  color: #aaa;
}
.c-select__txt::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  width: 22px;
  height: 18px;
  background: url("../img/common/txt_select_01.svg") no-repeat 50% 50%/contain;
}
.c-select__txt::after {
  position: absolute;
  display: inline-block;
  content: "";
  pointer-events: none;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: 20px;
  height: 11px;
  background: url("../../assets/img/common/ico_arrow_select.svg") no-repeat 0 0/cover;
}
@media only screen and (max-width: 767px) {
  .c-select__txt::after {
    right: 14px;
    width: 14px;
    height: 8px;
  }
}
.c-select select:disabled {
  color: #333;
  cursor: default;
}
.c-select select:disabled + .c-select__txt::before, .c-select select:disabled + .c-select__txt::after {
  opacity: 0.5;
}

/*--------------------------------------------------------------------------
   m-request-select
---------------------------------------------------------------------------*/
.m-request-select__item:not(:first-of-type) {
  margin-top: 10px;
}
.m-request-select__item:nth-child(2) .c-select__txt::before {
  width: 26px;
  height: 19px;
  background: url("../img/common/txt_select_02.svg") no-repeat 50% 50%/contain;
}
.m-request-select__item:nth-child(3) .c-select__txt::before {
  width: 26px;
  height: 19px;
  background: url("../img/common/txt_select_03.svg") no-repeat 50% 50%/contain;
}

/*--------------------------------------------------------------------------
   m-slide
---------------------------------------------------------------------------*/
.m-slide {
  max-width: calc(100vw - 80px);
  margin: 0 auto;
}
.m-slide .swiper {
  overflow: visible;
}
.m-slide .swiper-button-prev,
.m-slide .swiper-button-next {
  margin-top: -34px;
  width: 74px;
  height: 74px;
  background: #000;
  border-radius: 50%;
  transition: 0.3s;
}
@media only screen and (max-width: 767px) {
  .m-slide .swiper-button-prev,
  .m-slide .swiper-button-next {
    margin-top: -19px;
    width: 37px;
    height: 37px;
  }
}
.m-slide .swiper-button-prev::after,
.m-slide .swiper-button-next::after {
  font-size: 30px;
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .m-slide .swiper-button-prev::after,
  .m-slide .swiper-button-next::after {
    font-size: 20px;
  }
}
.m-slide .swiper-button-prev:hover,
.m-slide .swiper-button-next:hover {
  opacity: 0.8;
}
.m-slide .swiper-button-prev {
  margin-left: -40px;
}
@media only screen and (max-width: 767px) {
  .m-slide .swiper-button-prev {
    margin-left: -20px;
  }
}
.m-slide .swiper-button-next {
  margin-right: -40px;
}
@media only screen and (max-width: 767px) {
  .m-slide .swiper-button-next {
    margin-right: -20px;
  }
}

/*--------------------------------------------------------------------------
   m-request
---------------------------------------------------------------------------*/
.m-request {
  padding: 110px 0 80px;
  background: #fff69c;
}
@media only screen and (max-width: 767px) {
  .m-request {
    padding: 30px 0 50px;
  }
}
.m-request__header {
  position: relative;
  text-align: center;
}
.m-request__hdg {
  font-size: 32px;
  line-height: 1.5;
}
@media only screen and (max-width: 767px) {
  .m-request__hdg {
    font-size: 26px;
  }
}
.m-request__fuki {
  position: absolute;
  top: -70px;
  left: calc(50% - 320px);
}
@media only screen and (max-width: 767px) {
  .m-request__fuki {
    position: relative;
    top: auto;
    left: auto;
    width: 246px;
  }
}
@media only screen and (max-width: 767px) {
  .m-request__fuki img {
    width: 100%;
  }
}
.m-request__select {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding: 0 50px;
}
@media screen and (min-width: 768px) and (max-width: 940px) {
  .m-request__select {
    padding: 0 15px;
  }
}
@media only screen and (max-width: 767px) {
  .m-request__select {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
}
.m-request__select-item {
  max-width: 272px;
  width: 31%;
}
@media screen and (min-width: 768px) and (max-width: 940px) {
  .m-request__select-item {
    width: 32%;
  }
}
@media only screen and (max-width: 767px) {
  .m-request__select-item {
    max-width: 306px;
    width: 100%;
    margin: 0 auto;
  }
}
.m-request__select-item.m-request-select__item {
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .m-request__select-item.m-request-select__item {
    margin: 0 0 20px;
  }
}
.m-request__select-item.m-request-select__item .c-select {
  height: 64px;
  font-size: 20px;
}
@media only screen and (max-width: 767px) {
  .m-request__select-item.m-request-select__item .c-select {
    height: 58px;
    font-size: 19px;
  }
}
.m-request__link {
  position: relative;
  width: 473px;
  margin: 50px auto 0;
}
@media only screen and (max-width: 767px) {
  .m-request__link {
    max-width: 306px;
    width: 100%;
    margin-top: 10px;
  }
}
.m-request__link::before {
  content: "";
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 21px;
  background: url("../img/common/request_ico_arrow.svg") no-repeat 50% 50%/cover;
}
@media only screen and (max-width: 767px) {
  .m-request__link::before {
    content: none;
  }
}
.m-request__link-catch {
  font-size: 24px;
}
@media only screen and (max-width: 767px) {
  .m-request__link-catch {
    font-size: 21px;
    text-align: center;
  }
}
.m-request__link-catch span {
  display: inline-block;
  margin-right: 5px;
  transform: scale(-1, 1);
}
.m-request__link-btn {
  width: 473px;
  margin-top: 6px;
}
@media only screen and (max-width: 767px) {
  .m-request__link-btn {
    max-width: 306px;
    width: 100%;
  }
}
.m-request__link-btn.c-btn-request {
  font-size: 19px;
}
.m-request__link-btn.c-btn-request a {
  border: 1px solid #fff;
  border-radius: 40px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

/*--------------------------------------------------------------------------
   m-sp-footbtn
---------------------------------------------------------------------------*/
.m-sp-footbtn {
  display: none;
}
@media only screen and (max-width: 767px) {
  .m-sp-footbtn {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(100px);
    z-index: 9;
    transition: 0.3s;
  }
}
.m-sp-footbtn.is-show {
  transform: translateY(0);
}
.m-sp-footbtn__list {
  display: flex;
}
.m-sp-footbtn__btn {
  width: 50%;
}
.m-sp-footbtn__btn.c-btn-contact a {
  border-radius: 0;
}
.m-sp-footbtn__btn.c-btn-request a {
  border-radius: 0;
}

/*--------------------------------------------------------------------------
   cf
---------------------------------------------------------------------------*/
.cf {
  *zoom: 1;
}
.cf:after {
  content: "";
  display: table;
  clear: both;
}

/**
 *
 *  LOADER
 *
 */
/*--------------------------------------------------------------------------
   loader
---------------------------------------------------------------------------*/
#Loader {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgb(255, 255, 255);
  z-index: 9999999;
}

#Loader .progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 0px;
  height: 5px;
  background: #fff69c;
}

#Loader .base {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
  height: 189px;
  background-size: contain;
  background-position: 50% 50%;
  opacity: 0;
  z-index: 9;
}
@media only screen and (max-width: 767px) {
  #Loader .base {
    width: 224px;
    height: 132px;
  }
}

.page-index #Loader {
  display: block;
}

/* trans
-------------------------------------------*/
/* anima
-------------------------------------------*/
.load-complete .g-main {
  opacity: 1;
  transition: all 0.6s ease-in-out;
  transition-delay: 0.4s;
}

.is-loading .page-index #Loader .base {
  opacity: 1;
  transition: opacity 0.6s ease-in-out;
}

.is-loaded .page-index #Loader .base {
  transition: all 0.6s ease-in-out;
  opacity: 0;
}

.load-complete .page-index #Loader {
  opacity: 0;
  transition: all 0.6s ease-in-out;
  transition-delay: 0.4s;
}

/*--------------------------------------------------------------------------
   SHOW / HIDE
---------------------------------------------------------------------------*/
/*
 show / hide
 -- レスポンシブ用 表示/非表示
*/
.mi-pc {
  display: block;
}

img.mi-pc {
  display: inline;
}

.mi-sp {
  display: none;
}

/* media query -> sp
========================================*/
@media only screen and (max-width: 767px) {
  .mi-pc,
  img.mi-pc {
    display: none;
  }
  .mi-sp {
    display: block;
  }
  img.mi-sp {
    display: inline;
  }
}
/*--------------------------------------------------------------------------
   TEXT
---------------------------------------------------------------------------*/
/*
 note
 -- 改行時、一文字目に余白を持たせる
*/
.mi-note,
.mi-list-note li {
  padding-left: 1em;
  text-indent: -1em;
}

/*--------------------------------------------------------------------------
   IMAGE
---------------------------------------------------------------------------*/
/*
 max
 -- 横幅に合わせて画像を最大化
*/
.mi-img-max {
  width: 100%;
  height: auto;
}

/*--------------------------------------------------------------------------
   HOVER
---------------------------------------------------------------------------*/
/*
 hov01
 -- 透過
*/
/* setting */
/* core */
.mi-hov01 {
  transition: all 0.2s ease-in-out;
}

.mi-hov01:hover {
  opacity: 0.7;
}

.mi-hov01-all > * {
  transition: all 0.2s ease-in-out;
}

.mi-hov01-all > *:hover {
  opacity: 0.7;
}

/*--------------------------------------------------------------------------
   init
---------------------------------------------------------------------------*/
.is-animate.is-fadein {
  opacity: 0;
}
.is-animate.is-fadeup {
  opacity: 0;
  transform: translateY(30px);
}
.is-animate.is-fadedown {
  opacity: 0;
  transform: translateY(-30px);
}
.is-animate.is-fadeleft {
  opacity: 0;
  transform: translateX(30px);
}
.is-animate.is-faderight {
  opacity: 0;
  transform: translateX(-30px);
}
.is-animate.is-fadezoom {
  opacity: 0;
  transform: scale(0.4);
}

/*--------------------------------------------------------------------------
   animated
---------------------------------------------------------------------------*/
.is-animated.is-fadein {
  opacity: 1;
  transition: opacity 0.6s ease-in-out;
  transition-delay: 0s;
}
.is-animated.is-fadeup {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.6s ease-in-out, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-delay: 0s;
}
.is-animated.is-fadedown {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.6s ease-in-out, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-delay: 0s;
}
.is-animated.is-fadeleft {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.6s ease-in-out, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-delay: 0s;
}
.is-animated.is-faderight {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.6s ease-in-out, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-delay: 0s;
}
.is-animated.is-fadezoom {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.6s ease-in-out, transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-delay: 0s;
}

/*==========================================================================

   keyframes

===========================================================================*/
/*--------------------------------------------------------------------------
   .g-header
---------------------------------------------------------------------------*/
.g-header {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 76px;
  background: #fff;
}
@media only screen and (max-width: 767px) {
  .g-header {
    height: 55px;
  }
}
.g-header.is-fixed {
  position: fixed;
}
.g-header__logo {
  position: absolute;
  top: 16px;
  left: 11.33vw;
  z-index: 99;
  transition: top 0.4s;
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .g-header__logo {
    left: 50px;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .g-header__logo {
    top: 20px;
    left: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .g-header__logo {
    top: 11px;
    left: 12px;
  }
}
.g-header__logo a {
  display: inline-block;
}
.g-header__logo img {
  width: 316px;
  height: auto;
  transition: 0.3s;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .g-header__logo img {
    width: 246px;
  }
}
@media only screen and (max-width: 767px) {
  .g-header__logo img {
    width: 221px;
  }
}
.g-header__inquiry {
  display: flex;
  position: absolute;
  top: 0;
  right: 110px;
}
@media only screen and (max-width: 767px) {
  .g-header__inquiry {
    display: none;
  }
}
.g-header__inquiry .ico {
  margin-right: 8px;
  line-height: 0;
}
.g-header__contact {
  width: 198px;
  height: 76px;
  font-size: 20px;
  font-weight: bold;
}
.g-header__contact a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #b1e3e9;
}
.g-header__contact a:hover {
  opacity: 0.8;
}
.g-header__request {
  width: 198px;
  height: 76px;
  font-size: 20px;
  font-weight: bold;
}
.g-header__request a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #ff6964;
  color: #fff;
}
.g-header__request a:hover {
  opacity: 0.8;
}
.g-header__menubtn {
  /*display: flex;
  align-items: center;
  justify-content: center;*/
  position: absolute;
  top: 0;
  right: 0;
  width: 110px;
  height: 76px;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 99;
}
@media only screen and (max-width: 767px) {
  .g-header__menubtn {
    width: 55px;
    height: 55px;
  }
}
.g-header__menubtn::after {
  content: "メニュー";
  display: block;
  position: absolute;
  bottom: 3px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 16px;
  font-weight: bold;
}
@media only screen and (max-width: 767px) {
  .g-header__menubtn::after {
    content: none;
    font-size: 10px;
  }
}
.mode-pc .g-header__menubtn:hover .lines, .mode-sp .g-header__menubtn:active .lines {
  animation: h_menu_bounce 2s ease-in-out;
}
@keyframes h_menu_bounce {
  5% {
    transform: scale(1.5, 1);
  }
  10% {
    transform: scale(0.7, 1);
  }
  15% {
    transform: scale(1, 1);
  }
}
.g-header__menubtn .lines {
  position: absolute;
  top: 20px;
  left: 35px;
  margin: 0;
  padding: 0;
  width: 38px;
  height: 22px;
  font-size: 0;
  text-indent: -9999px;
  appearance: none;
  box-shadow: none;
  border-radius: 0;
  border: none;
}
@media only screen and (max-width: 767px) {
  .g-header__menubtn .lines {
    top: 19px;
    left: 13px;
    width: 28px;
    height: 16px;
  }
}
.g-header__menubtn .lines:focus {
  outline: none;
}
.g-header__menubtn .lines span {
  display: inline-block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  transition: all 0.4s;
  box-sizing: border-box;
  background-color: #111;
}
.g-header__menubtn .lines span:nth-of-type(1) {
  top: 0px;
  animation: menu-bar01 0.75s forwards;
}
.g-header__menubtn .lines span:nth-of-type(2) {
  top: 10px;
  transition: all 0.25s linear;
  opacity: 1;
}
@media only screen and (max-width: 767px) {
  .g-header__menubtn .lines span:nth-of-type(2) {
    top: 7px;
  }
}
.g-header__menubtn .lines span:nth-of-type(3) {
  bottom: 0px;
  animation: menu-bar02 0.75s forwards;
}
.g-header__wrap {
  position: relative;
  z-index: 9;
  position: absolute;
  top: 0px;
  right: 0;
  max-width: 800px;
  width: 100%;
  height: calc(100vh - 0px);
  background-color: #fff;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s linear;
}
@media only screen and (max-width: 767px) {
  .g-header__wrap {
    height: calc(var(--vh) * 100);
  }
}
.g-header__wrap-inner {
  padding: 90px;
}
@media only screen and (max-width: 767px) {
  .g-header__wrap-inner {
    height: 100%;
    padding: 60px 0 0px;
  }
}
.g-header__gnav {
  width: 100%;
  height: 100%;
  min-height: 0;
  overflow: auto;
}
.g-header__gnav .gnav {
  width: 50%;
}
@media only screen and (max-width: 767px) {
  .g-header__gnav .gnav {
    width: auto;
    padding: 0 20px;
  }
}
.g-header__gnav .gnav__item {
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .g-header__gnav .gnav__item {
    margin-bottom: 0;
  }
}
.g-header__gnav .gnav__item > a {
  position: relative;
  font-size: 17px;
  font-weight: 600;
}
@media only screen and (max-width: 767px) {
  .g-header__gnav .gnav__item > a {
    display: block;
    padding: 8px 5px 8px 20px;
    border-bottom: 1px solid #ccc;
    font-size: 18px;
  }
}
.mode-pc .g-header__gnav .gnav__item > a:hover, .mode-sp .g-header__gnav .gnav__item > a:active {
  color: #ff6964;
}
.g-header__gnav-wrap {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .g-header__gnav-wrap {
    display: block;
  }
}
.g-header .sp-wrap {
  display: none;
}
@media only screen and (max-width: 767px) {
  .g-header .sp-wrap {
    display: block;
    margin: 30px 0px 0;
    padding: 20px 50px 30px;
    background: #fff69c;
  }
}
.g-header .sp-wrap .sp-request {
  font-weight: bold;
  text-align: center;
}
.g-header .sp-wrap .sp-request__txt01 {
  font-size: 19px;
}
.g-header .sp-wrap .sp-request__txt01 span {
  font-size: 35px;
  line-height: 1;
}
.g-header .sp-wrap .sp-request__txt02 {
  font-size: 29px;
}
.g-header .sp-wrap .m-request-select {
  margin: 20px 0 0;
}
.g-header .sp-wrap .c-btn-request,
.g-header .sp-wrap .c-btn-contact {
  width: 100%;
  height: 50px;
  margin-top: 20px;
}
.g-header .sp-wrap .c-btn-request a,
.g-header .sp-wrap .c-btn-request button,
.g-header .sp-wrap .c-btn-contact a,
.g-header .sp-wrap .c-btn-contact button {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
.g-header .inquiry {
  display: flex;
  margin-top: 40px;
}
@media only screen and (max-width: 767px) {
  .g-header .inquiry {
    display: none;
  }
}
.g-header .inquiry .c-btn-contact {
  margin-right: 26px;
}
.g-header__fuji {
  margin-top: 40px;
}
@media only screen and (max-width: 767px) {
  .g-header__fuji {
    margin-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }
}
.g-header__fuji img {
  width: 167px;
}
@media only screen and (max-width: 767px) {
  .g-header__fuji img {
    width: 213px;
  }
}
.g-header__fuji a {
  position: relative;
}
@media only screen and (max-width: 767px) {
  .g-header__fuji a {
    padding-right: 20px;
  }
}
.g-header__fuji a::after {
  content: "";
  position: absolute;
  top: 2px;
  right: -18px;
  width: 13px;
  height: 13px;
  background: url("../img/common/ico_blank.svg") no-repeat 50% 50%/cover;
}
@media only screen and (max-width: 767px) {
  .g-header__fuji a::after {
    top: 4px;
    right: -6px;
    width: 17px;
    height: 17px;
  }
}
.g-header__fuji a:hover {
  opacity: 0.7;
}

/*menu open*/
.is-menuopen {
  overflow: hidden;
}
.is-menuopen .g-header__menubtn {
  /*border: 1px solid #fff;
  background-color: transparent;*/
}
.is-menuopen .g-header__menubtn::after {
  content: "閉じる";
}
.is-menuopen .g-header .lines span:nth-of-type(1) {
  transform: translateY(10px) rotate(-45deg);
}
@media only screen and (max-width: 767px) {
  .is-menuopen .g-header .lines span:nth-of-type(1) {
    transform: translateY(6px) rotate(-45deg);
  }
}
.is-menuopen .g-header .lines span:nth-of-type(2) {
  opacity: 0;
}
.is-menuopen .g-header .lines span:nth-of-type(3) {
  transform: translateY(-10px) rotate(45deg);
}
@media only screen and (max-width: 767px) {
  .is-menuopen .g-header .lines span:nth-of-type(3) {
    transform: translateY(-8px) rotate(45deg);
  }
}
.is-menuopen .g-header__wrap {
  pointer-events: all;
  visibility: visible;
  opacity: 1;
  transition: all 0.4s linear;
}

/*--------------------------------------------------------------------------
   .g-footer
---------------------------------------------------------------------------*/
@media only screen and (max-width: 767px) {
  .g-footer {
    padding-bottom: 64px;
  }
}
.g-footer__pagetop {
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  position: fixed;
  right: 20px;
  bottom: 70px;
  z-index: 9;
  transition: 0.3s;
}
@media only screen and (max-width: 767px) {
  .g-footer__pagetop {
    right: 15px;
    bottom: 85px;
  }
}
.g-footer__pagetop a {
  display: block;
  width: 46px;
  height: 46px;
  transition: scale 0.2s;
}
@media only screen and (max-width: 767px) {
  .g-footer__pagetop a {
    width: 40px;
    height: 40px;
  }
}
.g-footer__pagetop a img {
  width: 100%;
  height: auto;
}
.g-footer__pagetop a:hover {
  scale: 1.2;
}
.g-footer__pagetop.is-show {
  opacity: 1;
  pointer-events: all;
  overflow: visible;
}
.g-footer__bnrs {
  padding: 20px 0;
  background: #f5f1e5;
}
@media only screen and (max-width: 767px) {
  .g-footer__bnrs {
    padding-bottom: 10px;
  }
}
.g-footer__bnrlist {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.g-footer__bnrlist-item {
  padding: 0px 10px;
  background: #fff;
  border-radius: 10px;
  font-size: 21px;
}
@media only screen and (max-width: 767px) {
  .g-footer__bnrlist-item {
    margin-bottom: 10px;
  }
}
.g-footer__bnrlist-item:not(:first-of-type) {
  margin-left: 20px;
}
@media only screen and (max-width: 767px) {
  .g-footer__bnrlist-item:not(:first-of-type) {
    margin-left: 10px;
  }
}
.g-footer__logo-area {
  padding: 30px 0;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .g-footer__logo-area {
    padding: 20px 0;
  }
}
.g-footer__logo-area .logo {
  width: 238px;
  margin: 0 auto;
}
.g-footer__logo-area .logo img {
  width: 100%;
}
.g-footer__copy {
  padding: 10px 0;
  background: #4d4d4d;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  letter-spacing: 0.1em;
  text-align: center;
}
/*--------------------------------------------------------------------------
   .g-main
---------------------------------------------------------------------------*/
.g-main {
  padding-top: 76px;
  opacity: 0;
}
@media only screen and (max-width: 767px) {
  .g-main {
    padding-top: 55px;
  }
}

.page-index .g-main {
  opacity: 1;
}