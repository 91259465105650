/*--------------------------------------------------------------------------
   form
---------------------------------------------------------------------------*/
input,
button,
select,
textarea {
	border-radius: 0;
	-ms-appearance: none;
	appearance: none;
}

form input,
form textarea {
	border: 0;
}

input[type=checkbox],
input[type=radio] {
	position: absolute;
	opacity: 0;
}


/* 入力 */
.c-input {
	width: 100%;
	height: 60px;
	padding: 0 10px 0 20px;
  box-sizing: border-box;
	border: 1px solid #ddd;
	border-radius: 5px;
	background-color: #fff;
	font-size: 16px;

	@include mq-sp {
		height: 50px;
		padding: 5px 15px;
		font-size: 14px;
  }
}

.c-textarea {
	width: 100%;
	max-height: 240px;
	padding: 16px 10px 15px 20px;
	box-sizing: border-box;
	border: 1px solid #ddd;
	border-radius: 5px;
	background-color: #fff;
	font-size: 16px;
	overflow: auto;

	@include mq-sp {
		max-height: 200px;
		padding: 10px 12px;
		font-size: 14px;
	}
}

.c-input,
.c-textarea {
  &.ipt-err {
    background-color: #ffe4e4;
  }
}

/* radio */
.c-radio {
  & + label {
		position: relative;
		display: inline-block;
		padding-left: 30px;
		border-radius: 50%;
		box-sizing: border-box;
		vertical-align: middle;
		cursor: pointer;

    &::before,
    &::after {
      position: absolute;
			top: 50%;
      border-radius: 50%;
      content: '';
    }

    &::before {
			left: 0;
			width: 20px;
			height: 20px;
			transform: translateY(-50%);
			border: 1px solid #b7b7b7;
			background: #fff;
    }

    &::after {
			left: 4px;
			width: 12px;
			height: 12px;
			margin-top: -6px;
			background-color: $key-color;
			opacity: 0;
    }
  }

  &:checked + label::after {
    opacity: 1;
    transform: scale(1);
  }
}

/* checkbox */
.c-checkbox {
	visibility: hidden;
	position: absolute;

	& + label {
		display: inline-block;
		box-sizing: border-box;
		position: relative;
		padding-left: 30px;
		vertical-align: middle;
		cursor: pointer;

		@include mq-sp {
			padding-left: 28px;
		}

		&::before {
			display: block;
			position: absolute;
			top: 5px;
			left: 0;
			width: 20px;
			height: 20px;
			border: 1px solid #b7b7b7;
			background: #fff;
			content: '';

			@include mq-sp {
				top: 0;
				width: 20px;
				height: 20px;
			}
		}

		&::after {
			display: block;
			position: absolute;
			top: 6px;
			left: 6px;
			width: 8px;
			height: 16px;
			border-right: 3px solid $key-color;
			border-bottom: 3px solid $key-color;
			content: '';
			transform: rotate(45deg);
			opacity: 0;

			@include mq-sp {
				top: 2px;
				left: 6px;
				width: 8px;
				height: 14px;
			}
		}
	}
}

.c-checkbox:checked + label::after {
	opacity: 1;
}

.mwform-checkbox-field .c-checkbox  {
  & + span {
		display: inline-block;
		box-sizing: border-box;
		position: relative;
		padding-left: 30px;
		vertical-align: middle;
		cursor: pointer;

		@include mq-sp {
			padding-left: 28px;
		}

		&::before {
			display: block;
			position: absolute;
			top: 5px;
			left: 0;
			width: 18px;
			height: 18px;
			border: 1px solid #b7b7b7;
			background: #fff;
			content: '';

			@include mq-sp {
				top: 0;
				width: 20px;
				height: 20px;
			}
		}

		&::after {
			display: block;
			position: absolute;
			top: 5px;
			left: 5px;
			width: 8px;
			height: 14px;
			border-right: 3px solid $key-color;
			border-bottom: 3px solid $key-color;
			content: '';
			transform: rotate(45deg);
			opacity: 0;

			@include mq-sp {
				top: 2px;
				left: 6px;
				width: 8px;
				height: 14px;
			}
		}
	}

  &:checked + span::after {
    opacity: 1;
  }
}

/* select
.c-select {
  position: relative;
  display: inline-block;
	width: 220px;
  height: 50px;
	background-color: #fff;
  border: 1px solid #333;
  border-radius: 10px;

	@include mq-sp {
		height: 50px;
  }

  &::before {
    position: absolute;
    display: inline-block;
    //content: '';
    pointer-events: none;
    top: 50%;
    right: 20px;
		transform: translateY(-50%);
    width: 20px;
    height: 11px;
		background: url("../../assets/img/common/ico_arrow_select.svg") no-repeat 0 0/ cover;

		@include mq-sp {
	    right: 14px;
      width: 14px;
      height: 8px;
    }
  }

  select {
    width: 100%;
    height: 100%;
    padding: 0 25px 0 60px;
		font-size: 17px;
    font-weight: bold;
		cursor: pointer;

		@include mq-sp {
    }
  }
} */
