/*--------------------------------------------------------------------------
   HOVER
---------------------------------------------------------------------------*/

/*
 hov01
 -- 透過
*/

/* setting */
$hov01-ease: ease-in-out;
$hov01-opacity: .7;
$hov01-duration: .2s;

/* core */
.mi-hov01{
  transition: all $hov01-duration $hov01-ease;
}
.mi-hov01:hover{
  opacity: $hov01-opacity;
}

.mi-hov01-all > *{
  transition: all $hov01-duration $hov01-ease;
}
.mi-hov01-all > *:hover{
  opacity: $hov01-opacity;
}
