/*--------------------------------------------------------------------------
   l-wrap
---------------------------------------------------------------------------*/
.l-wrap {
	position: relative;
	max-width: $min-width-pc;
	margin: 0 auto;
	padding: 0 10px;
}

.l-wrap-s {
	position: relative;
	max-width: 954px;
	margin: 0 auto;
	padding: 0 10px;
}


/* media query -> sp
=================================================================*/
@media #{$bp-sp} {
	.l-wrap {
		max-width: none;
		padding: 0 20px;
	}

	.l-wrap-s {
		max-width: none;
		padding: 0 20px;
	}
}
