/*--------------------------------------------------------------------------
   .g-main
---------------------------------------------------------------------------*/
.g-main {
  padding-top: 76px;
  opacity: 0;

  @media screen and (max-width:1000px) {
    //padding-top: 70px;
  }

  @include mq-sp {
    padding-top: 55px;
  }
}

.page-index .g-main {
  opacity: 1;
}
