/*--------------------------------------------------------------------------
   m-request
---------------------------------------------------------------------------*/
.m-request {
  padding: 110px 0 80px;
  background: $key-color;

  @include mq-sp {
    padding: 30px 0 50px;
  }

  &__content {
  }

  &__header {
    position: relative;
    text-align: center;

    @include mq-sp {
    }
  }

  &__hdg {
    font-size: 32px;
    line-height: 1.5;

    @include mq-sp {
      font-size: 26px;
    }
  }

  &__fuki {
    position: absolute;
    top: -70px;
    left: calc(50% - 320px);

    @include mq-sp {
      position: relative;
      top: auto;
      left: auto;
      width: 246px;
    }

    img {
      @include mq-sp {
        width: 100%;
      }
    }
  }

  &__select {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    padding: 0 50px;

    @media screen and (min-width:768px) and (max-width:940px) {
      padding: 0 15px;
    }
    @include mq-sp {
      flex-direction: column;
      align-items: center;
      padding: 0;
    }
  }

  &__select-item {
    max-width: 272px;
    width: 31%;

    @media screen and (min-width:768px) and (max-width:940px) {
      width: 32%;
    }
    @include mq-sp {
      max-width: 306px;
      width: 100%;
      margin: 0 auto;
    }

    &.m-request-select__item {
      margin: 0;

      @include mq-sp {
        margin: 0 0 20px;
      }

      .c-select {
        height: 64px;
        font-size: 20px;

        @include mq-sp {
          height: 58px;
          font-size: 19px;
        }
      }
    }
  }

  &__link {
    position: relative;
    width: 473px;
    margin: 50px auto 0;

    @include mq-sp {
      max-width: 306px;
      width: 100%;
      margin-top: 10px;
    }

    &::before {
      content: '';
      position: absolute;
      top: -8px;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
      height: 21px;
      background: url('../img/common/request_ico_arrow.svg') no-repeat 50% 50% / cover;

      @include mq-sp {
        content: none;
      }
    }

    &-catch {
      font-size: 24px;

      @include mq-sp {
        font-size: 21px;
        text-align: center;
      }

      span {
        display: inline-block;
        margin-right: 5px;
        transform: scale(-1, 1);
      }
    }

    &-btn {
      width: 473px;
      margin-top: 6px;

      @include mq-sp {
        max-width: 306px;
        width: 100%;
      }

      &.c-btn-request {
        font-size: 19px;

        a {
          border: 1px solid #fff;
          border-radius: 40px;
          box-shadow: 2px 2px 8px rgba(#000, .2);
        }
      }
    }
  }
}
