/*--------------------------------------------------------------------------
   m-request-select
---------------------------------------------------------------------------*/
.m-request-select {

  &__item {
    &:not(:first-of-type) {
      margin-top: 10px;
    }

    &:nth-child(2) {
      .c-select__txt {
        &::before {
          width: 26px;
          height: 19px;
          background: url('../img/common/txt_select_02.svg') no-repeat 50% 50% / contain;
        }
      }
    }

    &:nth-child(3) {
      .c-select__txt {
        &::before {
          width: 26px;
          height: 19px;
          background: url('../img/common/txt_select_03.svg') no-repeat 50% 50% / contain;
        }
      }
    }
  }
}
