/*--------------------------------------------------------------------------
   l-grid
---------------------------------------------------------------------------*/
.l-grid {
	box-sizing: border-box;

  *zoom: 1;
  &:after {
    content: "";
    display: table;
    clear: both;
  }

	&__item {
		display: block;
		box-sizing: inherit;
		float: left;
	}
}

.l-grid__item {
	&--1of2 {
		width: 50%;
	}

	&--1of3 {
		width: 33.33333333%;
	}

	&--1of4 {
		width: 25%;
	}

	&--1of5 {
		width: 20%;
	}

	&--1of6 {
		width: 16.66666666%;
	}
}
